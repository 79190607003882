/** @format */

import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import Alert from "@theme/components/Alert";
import Button from "@theme/components/Button";
import Divider from "@theme/components/Divider";
import FormLabel from "@theme/components/FormLabel";
import Input from "@theme/components/Input";
import Link from "@theme/components/Link";
import Menu from "@theme/components/Menu";
import Modal from "@theme/components/Modal";
import NumberInput from "@theme/components/NumberInput";
import Select from "@theme/components/Select";
import Table from "@theme/components/Table";
import Tabs from "@theme/components/Tabs";
import Textarea from "@theme/components/Textarea";
import styles from "@theme/styles";

const config: ThemeConfig = {
    initialColorMode: "system",
    useSystemColorMode: false,
};

const components = {
    Button,
    Input,
    Table,
    Link,
    Tabs,
    Divider,
    Textarea,
    Select,
    Alert,
    FormLabel,
    Modal,
    Menu,
    NumberInput,
};

const fonts = {
    body: "Open Sans, sans-serif",
    heading: "Inter, sans-serif",
};

// 3. extend the theme

const theme = extendTheme({ ...styles, fonts, config, components });

export default theme;
