/** @format */

import * as Sentry from "@sentry/react";

const checkSentry =
    import.meta.env.VITE_RELEASE_VERSION && import.meta.env.VITE_NODE_ENV !== "development";

export const initSentry = () => {
    if (!checkSentry) {
        return;
    }

    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `memida-frontend@${import.meta.env.VITE_RELEASE_VERSION}`,
        debug: import.meta.env.VITE_NODE_ENV === "development",
        environment: import.meta.env.VITE_NODE_ENV === "staging" ? "staging" : "production",
        beforeSend(event) {
            if (event.user) {
                delete event.user.ip_address;
                delete event.user.email;
            }

            return event;
        },
    });
};

export const captureSentryException: typeof Sentry.captureException = (...args) => {
    if (!checkSentry) {
        return "";
    }

    return Sentry.captureException(...args);
};
