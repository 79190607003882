/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { DateTime } from "luxon";

export type Archivetype = "archive" | "unarchive";

export type DownloadType = "xls" | "xlsx" | "ods" | "pdf";
export interface FileExportsModel {
    uuid: string;
    updatedName: string;
    name: string;
    archived: boolean;
    url: string;
    types: DownloadType[];
    created: DateTime;
}

export default class FileExportAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("FilesExports", "FilesExport", "FilesExports", adapter);
    }

    public async archiveFiles(id: string, mode: Archivetype = "archive"): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`archiveFiles/${id}/${mode}`);

        return data.success;
    }

    public async archiveFilesName(id: string, name: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`archiveFilesName/${id}`, { name });

        return data.success;
    }

    public async indexFile(): Promise<FileExportsModel[]> {
        const { data } = await this._get<{ filesExport: FileExportsModel[] }>(`indexFile`);

        return data.filesExport;
    }

    public async deleteFile(id: string): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`deleteFile/${id}`);

        return data.success;
    }
}
