/** @format */

import { alertColors } from "@theme/components/Alert";
import { buttonColors } from "@theme/components/Button";
import { inputColors } from "@theme/components/Input";
import { linkColors } from "@theme/components/Link";
import { menuColors } from "@theme/components/Menu";
import { tableColors } from "@theme/components/Table";
import { tabsColors } from "@theme/components/Tabs";
import defaultColors from "@theme/defaultColors";

const styles = {
    styles: {
        global: {
            body: {
                bg: "#F5F5F4",
                letterSpacing: "tighter",
            },
        },
    },
    colors: {
        ...defaultColors,
        buttons: buttonColors,
        inputs: inputColors,
        tables: tableColors,
        links: linkColors,
        tabs: tabsColors,
        textarea: inputColors,
        select: inputColors,
        alert: alertColors,
        menu: menuColors,
    },
};

export default styles;
