/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export enum InspectionResult {
    OPERATIONAL = "operational",
    OPERATIONAL_AFTER_INTERVENTION = "operational_after_intervention",
    CONDITIONALLY_OPERATIONAL = "conditionally_operational",
    NOT_OPERATIONAL = "not_operational",
    DEFECT = "defect",
    DECOMMISSIONED = "decommissioned",
    UNDETECTABLE = "undetectable",
    SCRAPPED = "scrapped",
    RECOVERED = "recovered",
    SEE_REPORT = "see_report",
    SEE_PDF = "see_pdf",
}

export interface InspectionResultModel extends Model {
    name: InspectionResult;
}

export interface InspectionResultModelPaginator extends Paginator {
    inspectionResults: InspectionResultModel[];
}

export default class InspectionResultAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("inspectionResults", "inspectionResult", "inspectionResults", adapter);
    }

    public async getIndexList(): Promise<InspectionResultModel[]> {
        const { data } = await this._get<{ inspectionResults: InspectionResultModel[] }>(
            `getIndex`
        );

        return data.inspectionResults;
    }
}
