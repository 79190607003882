/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";
import defaultColors from "@theme/defaultColors";

export const alertColors = {
    light: {
        green: {
            bg: defaultColors.green[30],
            color: defaultColors.grey[100],
            accent: defaultColors.green[60],
            solid: {
                bg: defaultColors.green[100],
                color: defaultColors.white,
                accent: defaultColors.green[100],
            },
        },
        red: {
            bg: defaultColors.red[30],
            color: defaultColors.grey[100],
            accent: defaultColors.red[100],
            solid: {
                bg: defaultColors.red[100],
                color: defaultColors.white,
                accent: defaultColors.red[100],
            },
        },
        orange: {
            bg: defaultColors.orange[30],
            color: defaultColors.grey[100],
            accent: defaultColors.orange[100],
            solid: {
                bg: defaultColors.orange[100],
                color: defaultColors.white,
                accent: defaultColors.orange[100],
            },
        },
        blue: {
            bg: defaultColors.blue[30],
            color: defaultColors.grey[90],
            accent: defaultColors.blue[60],
            solid: {
                bg: defaultColors.blue[100],
                color: defaultColors.white,
                accent: defaultColors.blue[100],
            },
        },
    },
    dark: {
        green: {
            bg: defaultColors.green[30],
            color: defaultColors.grey[90],
            accent: defaultColors.green[100],
            solid: {
                bg: defaultColors.green[100],
                color: defaultColors.white,
                accent: defaultColors.white,
            },
        },
        red: {
            bg: defaultColors.red[30],
            color: defaultColors.grey[90],
            accent: defaultColors.red[100],
            solid: {
                bg: defaultColors.red[100],
                color: defaultColors.white,
                accent: defaultColors.white,
            },
        },
        orange: {
            bg: defaultColors.orange[30],
            color: defaultColors.grey[90],
            accent: defaultColors.orange[100],
            solid: {
                bg: defaultColors.orange[100],
                color: defaultColors.white,
                accent: defaultColors.white,
            },
        },
        blue: {
            bg: defaultColors.blue[30],
            color: defaultColors.grey[90],
            accent: defaultColors.blue[60],
            solid: {
                bg: defaultColors.blue[60],
                color: defaultColors.white,
                accent: defaultColors.white,
            },
        },
    },
};
// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Alert: ComponentStyleConfig = {
    parts: ["container"],
    baseStyle: ({ colorMode }) => ({
        container: {
            boxShadow: "md",
            rounded: "md",
        },
    }),
    variants: {
        subtle: ({ colorScheme, colorMode }) => ({
            container: {
                bg: `alert.${colorMode}.${colorScheme}.bg`,
                color: `alert.${colorMode}.${colorScheme}.color`,
                borderColor: `alert.${colorMode}.${colorScheme}.solid.accent`,
            },
            icon: {
                color: `alert.${colorMode}.${colorScheme}.solid.color`,
            },
            spinner: {
                color: `alert.${colorMode}.${colorScheme}.solid.color`,
            },
        }),
        solid: ({ colorScheme, colorMode }) => ({
            container: {
                bg: `alert.${colorMode}.${colorScheme}.solid.bg`,
                color: `alert.${colorMode}.${colorScheme}.solid.color`,
                borderColor: `alert.${colorMode}.${colorScheme}.solid.accent`,
            },
            icon: {
                color: `alert.${colorMode}.${colorScheme}.solid.color`,
            },
            spinner: {
                color: `alert.${colorMode}.${colorScheme}.solid.color`,
            },
        }),
        "left-accent": ({ colorScheme, colorMode }) => ({
            container: {
                bg: `alert.${colorMode}.${colorScheme}.bg`,
                color: `alert.${colorMode}.${colorScheme}.color`,
                borderColor: `alert.${colorMode}.${colorScheme}.accent`,
            },
            icon: {
                color: `alert.${colorMode}.${colorScheme}.accent`,
            },
            spinner: {
                color: `alert.${colorMode}.${colorScheme}.accent`,
            },
        }),
        "top-accent": ({ colorScheme, colorMode }) => ({
            container: {
                bg: `alert.${colorMode}.${colorScheme}.bg`,
                color: `alert.${colorMode}.${colorScheme}.color`,
                borderColor: `alert.${colorMode}.${colorScheme}.accent`,
            },
            icon: {
                color: `alert.${colorMode}.${colorScheme}.accent`,
            },
            spinner: {
                color: `alert.${colorMode}.${colorScheme}.accent`,
            },
        }),
    },
};

export default Alert;
