/** @format */

import { Spinner } from "@chakra-ui/react";
import LoadingContext from "@context/LoadingProvider";
import { motion } from "framer-motion";
import React, { useContext } from "react";

import "./PopUpLoading.scss";

const PopUpLoading: React.FC = () => {
    const { loading } = useContext(LoadingContext);

    const variants = {
        open: { opacity: 1, y: "-35px" },
        closed: { opacity: 0, y: "100%" },
    };

    return (
        <>
            <motion.div
                className="popup-logout"
                animate={loading ? "open" : "closed"}
                variants={variants}
            >
                <Spinner size="md" />
            </motion.div>
        </>
    );
};

export default PopUpLoading;
