/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { DateTime } from "luxon";

export interface FaqModel extends Model {
    id: number;
    title: string;
    answer: string;
    active_from: DateTime | null;
    active_to: DateTime | null;
    sort_order: number;
    is_active: boolean;
}

export interface FaqGroupEntry {
    groupName: string;
    entires: FaqModel[];
}

export interface FaqFindModel {
    [key: string]: FaqGroupEntry;
}

export default class FAQAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    FaqModel,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("faq/Faqs", "Faq", "Faqs", adapter);
    }

    public async getFaqs(search = ""): Promise<FaqFindModel> {
        const { data } = await this._get<{ faqs: FaqFindModel }>(
            `get-authenticated-faqs?search=${search}`
        );

        return data.faqs;
    }
}
