/** @format */

import deGlobalTranslation from "@locales/de/global.json";
import dePaymentTranslation from "@locales/de/payment.json";
import deTranslation from "@locales/de/translation.json";
import enGlobalTranslation from "@locales/en/global.json";
import enPaymentTranslation from "@locales/en/payment.json";
import enTranslation from "@locales/en/translation.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

void i18n
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: "de",
        // lng: Localization.locale,
        fallbackLng: "de",
        debug: false,
        saveMissing: false, // send not translated keys to endpoint
        keySeparator: ".", // we do not use keys in form messages.welcome
        initImmediate: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        preload: ["de", "en"],
        resources: {
            en: {
                translation: {
                    ...enTranslation,
                },
                global: {
                    ...enGlobalTranslation,
                },
                payment: {
                    ...enPaymentTranslation,
                },
            },
            de: {
                translation: {
                    ...deTranslation,
                },
                global: {
                    ...deGlobalTranslation,
                },
                payment: {
                    ...dePaymentTranslation,
                },
            },
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
