/**
 * Deep clone objects - asynchronously.
 *
 * This supports cyclic structures and preserves types (naïve JSON.parse(JSON.stringify) does not)
 * while being less efficient for small objects.
 * We use a MessageChannel to use "Structured cloning" which is not directly exposed at the time of writing.
 * A good read-up about deep-cloning in JavaScript can be found here: https://dassur.ma/things/deep-copy/
 *
 * @format
 * @param obj Object to clone
 * @return Deep clone of object
 */

function deepCloneAsync<T>(obj: T): Promise<T> {
    return new Promise((resolve) => {
        const { port1: input, port2: output } = new MessageChannel();
        output.onmessage = (ev: { data: T }) => resolve(ev.data);

        input.postMessage(obj);
    });
}

export default deepCloneAsync;
