/** @format */

import ApiAdapter from "@api/auth/ApiAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export enum PaymentMethod {
    CREDITCARD = "creditcard",
    DIRECTDEBIT = "directdebit",
    PAYPAL = "paypal",
    PAYMENT_ON_ACCOUNT = "payment_on_account",
}

export type SepaInfo = {
    name: string;
    iban: string;
    bic?: string;
};

export type Mandate = {
    id: string;
    createdAt: string;
    status: string;
} & (
    | {
          method: PaymentMethod.CREDITCARD;
          details: {
              cardHolder: string | null;
              cardNumber: string | null;
              cardLabel: string | null;
              cardFingerprint: string | null;
              cardExpiryDate: string | null;
          } | null;
      }
    | {
          method: PaymentMethod.DIRECTDEBIT;
          details: {
              consumerName: string | null;
              consumerAccount: string | null;
              consumerBic: string | null;
          } | null;
      }
    | {
          method: PaymentMethod.PAYPAL;
          details: {
              consumerName: string | null;
              consumerAccount: string | null;
          } | null;
      }
);

export default class MandateAdapter<RequestConfigType> extends ApiAdapter<RequestConfigType> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter, "payment/mandates");
    }

    async getMandates(): Promise<Mandate[]> {
        const response = await this._get<Mandate[]>("index");

        const mandates = response.data;
        if (!mandates) {
            throw new Error("invalid response");
        }

        return mandates;
    }

    async addSepaMandate(
        info: SepaInfo,
        apparatusCount: number,
        subscriptiontypeoptionId: number,
        reactivate: boolean,
        mandateId?: string
    ): Promise<boolean> {
        const { data } = await this._post<{ error?: boolean }>("add", {
            type: PaymentMethod.DIRECTDEBIT,
            name: info.name,
            iban: info.iban,
            bic: info.bic,
            apparatusCount,
            subscriptiontypeoptionId,
            reactivate,
            mandateId,
        });

        return !(data && data.error);
    }

    async addMandate(
        type: Exclude<PaymentMethod, PaymentMethod.DIRECTDEBIT>,
        redirect: string,
        apparatusCount: number,
        subscriptiontypeoptionId: number,
        reactivate: boolean,
        newDefault?: boolean,
        mandateId?: string
    ): Promise<string> {
        const response = await this._post<{ checkout: string }>("add", {
            type,
            redirect,
            newDefault: !!newDefault,
            apparatusCount,
            subscriptiontypeoptionId,
            reactivate,
            mandateId,
        });

        const checkout = response.data.checkout;

        if (!checkout) {
            throw new Error("invalid response");
        }

        return checkout;
    }

    async addOnAccountMandate(subscriptiontypeoptionId: number): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>("addOnAccountMandate", {
            subscriptiontypeoptionId,
        });

        return data.success;
    }

    async removeMandate(id: string): Promise<Mandate[]> {
        const response = await this._get<Mandate[]>(`remove/${id}`);

        const mandates = response.data;
        if (!mandates) {
            throw new Error("invalid response");
        }

        return mandates;
    }
}
