/** @format */

import { create } from "zustand";

interface PageTitleState {
    title: string;
    element: string | React.ReactElement;
    info: string;
    mini: boolean;
    miniHeadline: string;
    setTitle: (
        title: string,
        element?: string | React.ReactElement,
        info?: string,
        mini?: boolean,
        miniHeadline?: string
    ) => void;
}

const usePageTitleStore = create<PageTitleState>()((set) => ({
    title: "",
    element: "",
    info: "",
    mini: false,
    miniHeadline: "",
    setTitle: (title, element, info = "", mini = false, miniHeadline = "") =>
        set((state) => ({
            ...state,
            title,
            element,
            info,
            mini,
            miniHeadline,
        })),
}));

export default usePageTitleStore;
