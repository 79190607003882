/** @format */

import ApiAdapter from "@api/auth/ApiAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { DateTime } from "luxon";

import { ExtendedResponseError } from "./ExtendedUserAdapter";

export interface Ticket {
    id: number;
    uuid: string;
    author_id: number;
    addressee_id: null;
    category_id: number;
    priority_id: number;
    status_id: number;
    type_id: number;
    created: DateTime;
    modified: DateTime;
    author: TicketAuthor;
    addressee: TicketAddressee | null;
    status: TicketStatus;
    priority: TicketPriority;
    category: TicketCategory;
    messages: TicketMessage[];
    initial_message: TicketMessage;
    is_open: boolean;
}

export interface TicketAddressee {
    id: number;
    user_id: number;
    name: string;
    surname: string;
    email: string;
    phone: null;
    privacy_accepted: boolean;
    cleared_at: null;
    fullname: string;
}

export interface TicketAuthor {
    id: number;
    user_id: number;
    name: string;
    surname: string;
    email: string;
    phone: null;
    privacy_accepted: boolean;
    cleared_at: null;
    fullname: string;
}

export interface TicketCategory {
    id: number;
    title: string;
    color: string;
}

export interface TicketStatus {
    id: number;
    title: string;
    color: string;
    do_close: boolean;
}

export interface TicketPriority {
    id: number;
    title: string;
    color: string;
}

export interface TicketMessage {
    id: number;
    author_id: number;
    ticket_id: number;
    subject: string;
    text: string;
    is_first: boolean;
    created: DateTime;
    modified: DateTime;
    author: TicketAuthor;
}

export interface ReplyContactTicketResponse {
    success?: boolean;
    errors?: ExtendedResponseError;
}

export interface AddMessageResponse extends ReplyContactTicketResponse {
    ticket: Ticket;
    message: TicketMessage;
}

export default class TicketAdapter<RequestConfigType> extends ApiAdapter<RequestConfigType> {
    public constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter, "contactsystem/tickets");
    }

    public async checkToken(token: string, action: string): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>("check-token", {
            params: {
                t: token,
                action,
            },
        });

        return data.success;
    }

    public async getReplyContactTicket(token: string): Promise<{ token: boolean; ticket: Ticket }> {
        const { data } = await this._get<{ token: boolean; ticket: Ticket }>("replyContactTicket", {
            params: {
                t: token,
            },
        });

        return data;
    }

    public async replyContactTicket(
        token: string,
        subject: string,
        text: string
    ): Promise<ReplyContactTicketResponse> {
        const { data } = await this._post<ReplyContactTicketResponse>("replyContactTicket", {
            token,
            subject,
            text,
        });

        return data;
    }

    public async getAddTicket(): Promise<{ [key: number]: string }> {
        const { data } = await this._get<{ categories: { [key: number]: string } }>("addTicket");

        return data.categories;
    }

    public async addTicket(
        subject: string,
        text: string,
        type: string,
        categoryId?: number | null
    ): Promise<AddMessageResponse> {
        const { data } = await this._post<AddMessageResponse>("addTicket", {
            subject,
            text,
            type,
            category_id: categoryId,
        });

        return data;
    }

    public async addMessage(
        uuid: string,
        subject: string,
        text: string
    ): Promise<AddMessageResponse> {
        const { data } = await this._post<AddMessageResponse>(`addMessage/${uuid}`, {
            subject,
            text,
        });

        return data;
    }

    public async getTickets(): Promise<Ticket[]> {
        const response = await this._get<{
            tickets: Ticket[];
        }>("getTickets");

        const data = response.data.tickets;

        return data;
    }

    public async getTicket(uuid: string): Promise<Ticket | null | undefined> {
        const { data } = await this._get<{ ticket: Ticket | null | undefined }>(
            `getTicket/${uuid}`
        );

        return data.ticket;
    }

    public async closeTicket(uuid: string): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`closeTicket/${uuid}`);

        return data.success;
    }
}
