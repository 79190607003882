/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

import defaultColors from "../defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const FormLabel: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        color: colorMode === "light" ? defaultColors.blue[100] : defaultColors.blue[60],
        m: 0,
        pb: 2,
    }),
};

export default FormLabel;
