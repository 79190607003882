/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export default class UploadAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("core/Uploads", "core/Upload", "core/Uploads", adapter);
    }

    public async removeUpload(id: string): Promise<unknown> {
        const { data } = await this._delete<unknown>(`delete/${id}`);

        return data;
    }
}
