/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { PaginatorInner } from "@components/Table/TableBuilder";

export interface AccreditationCodes {
    id: number;
    accreditation_code: string;
    link: string;
    valid: string | null;
    withdrawn: string | null;
    exposed: string | null;
    partially_exposed: string | null;
}
export interface CalibrationPlaces {
    id: number;
    name: string;
    address?: string;
    accreditation_identifier?: string;
    calibration_place_accreditation_codes?: AccreditationCodes[];
}

export interface CalibrationPlacesModel {
    calibrationPlaces: CalibrationPlaces[];
    paginator: PaginatorInner;
}

export default class CalibrationPlacesAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("calibrationPlaces", "calibrationPlace", "calibrationPlaces", adapter);
    }

    public async index(search = "", page = 0): Promise<CalibrationPlacesModel> {
        const { data } = await this._get<CalibrationPlacesModel>(
            `index?search=${search}&page=${page}`
        );

        return data;
    }

    public async addPlace(
        name = "",
        id: number
    ): Promise<{ success: boolean; calibrationPlace?: CalibrationPlaces }> {
        const { data } = await this._post<{
            success: boolean;
            calibrationPlace?: CalibrationPlaces;
        }>(`add/${id}`, { name });

        return data;
    }

    public async getLocation(
        id: number
    ): Promise<{ calibrationPlace: CalibrationPlaces; location: string }> {
        const { data } = await this._get<{ calibrationPlace: CalibrationPlaces; location: string }>(
            `location/${id}`
        );

        return data;
    }
}
