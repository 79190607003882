/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { DefaultPaginationRequestData } from "@components/Table/TableBuilder";
import { PaginatorParams, fnPaginationParams } from "@hook/usePaginationParams";
import { ModelImage, UploadImage } from "@typings/UploadTypes";

export interface CompanyUserModel {
    id: number;
    name: string;
    surname: string;
    img?: ModelImage;
}

export interface CompanyModel extends Omit<Model, "id"> {
    id: number;
    name: string;
    address: string;
    zip: string;
    city: string;
    phone: string;
    email: string;
    users?: CompanyUserModel[];
    uploads?: UploadImage[];
}
export interface CompanyAddModel extends Omit<CompanyModel, "id"> {
    id?: number;
}

export interface CompanyModelPaginator extends Paginator {
    companies: CompanyModel[];
}

export default class CompanyAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    CompanyModel,
    CompanyModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("core/companies", "core/company", "core/companies", adapter);
    }

    public async index(
        paginator?: PaginatorParams
    ): Promise<DefaultPaginationRequestData<CompanyModel>> {
        const { params } = fnPaginationParams(paginator);
        const { data } = await this._get<DefaultPaginationRequestData<CompanyModel>>(
            `index${params}`
        );

        return data;
    }

    public async getOwnIndex(): Promise<{ [key: number]: string }> {
        const { data } = await this._get<{ companies: { [key: number]: string } }>(`getOwnIndex`);

        return data.companies;
    }

    public async getAdd(): Promise<CompanyModel> {
        const { data } = await this._get<CompanyModel>("add");

        return data;
    }

    public async saveAdd(company: CompanyAddModel, image: File | undefined): Promise<boolean> {
        const formData = new FormData();

        Object.keys(company).map((key) => {
            const keyType = key as keyof CompanyAddModel;

            formData.append(key, String(company[keyType]));
        });

        if (image) {
            formData.append("clientUploads[file]", image);
        }

        const { data } = await this._post<{ success: boolean }>(`add`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data.success;
    }

    public async getEdit(companyId: number): Promise<CompanyModel> {
        const { data } = await this._get<{ company: CompanyModel }>(`edit/${companyId}`);

        return data.company;
    }

    public async saveEdit(company: CompanyModel, image: File | undefined): Promise<boolean> {
        const formData = new FormData();

        Object.keys(company).map((key) => {
            const keyType = key as keyof CompanyModel;

            formData.append(key, String(company[keyType]));
        });

        if (image) {
            formData.append("clientUploads[file]", image);
        }

        const { data } = await this._post<{ success: boolean }>(`edit/${company.id}`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data.success;
    }
}
