/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export interface Invoice extends Model {
    id: number;
    invoice_number: string;
    file: string;
}

export default class InvoiceAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Invoice,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("invoice/Invoices", "Invoice", "Invoices", adapter);
    }

    public async getIndex(): Promise<Invoice[]> {
        const { data } = await this._get<{ invoices: Invoice[] }>(`index`);

        return data.invoices;
    }
}
