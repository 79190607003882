/** @format */

import { ModelWithId } from "@api/auth/ControllerAdapter";
import { AuthUserModel } from "@api/ext/GlobalUserAdapter";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";

import { authAdapter, userAdapter } from "../api/ApiRequest";

const fetchAuthUser: (
    signal: AbortSignal | undefined
) => Promise<ModelWithId<AuthUserModel>> = async (signal: AbortSignal | undefined) =>
    await userAdapter.whoami(signal);

const checkValidAuth = () => authAdapter.checkLogin();

type UseAuthRet = [boolean | null, AuthUserModel | undefined, () => void, () => void, () => void];

export const useAuth = (): UseAuthRet => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);

    const authRef = useRef<boolean>(false);

    const queryClient = useQueryClient();

    const {
        data: user,
        refetch: refetchUser,
        remove: removeUser,
    } = useQuery(
        ["AuthUser"],
        ({ signal }) => {
            return fetchAuthUser(signal);
        },
        {
            enabled: authenticated ?? false,
        }
    );

    const logout = useCallback((): void => {
        setAuthenticated((oldAuth) => {
            if (oldAuth) {
                void removeUser();
            }

            return false;
        });

        void authAdapter.logout().then(() => {
            void queryClient.resetQueries(["AuthUser"]);
            queryClient.clear();
        });
    }, [queryClient, removeUser]);

    const { refetch: refetchAuth } = useQuery(["AuthValidate"], checkValidAuth, {
        enabled: false,
        onSuccess({ authenticated }) {
            setAuthenticated((oldAuth) => {
                if (oldAuth === true && authenticated === false) {
                    logout();
                }

                return authenticated;
            });
        },
    });

    const validateAuth = useCallback(() => {
        void refetchAuth();
    }, [refetchAuth]);

    useEffect(() => {
        if (!authRef.current) {
            authRef.current = true;
            validateAuth();
        }
    }, [validateAuth]);

    const refetchAuthUser = useCallback(() => {
        void refetchUser();
    }, [refetchUser]);

    return [authenticated, user, refetchAuthUser, logout, validateAuth];
};
