/** @format */

import { create } from "zustand";

interface LoginRedirectState {
    to: string | null;
    setTo: (text: string) => void;
    removeTo: () => void;
}

const useLoginRedirectStore = create<LoginRedirectState>()((set) => ({
    to: null,
    setTo: (to) =>
        set((state) => ({
            ...state,
            to,
        })),
    removeTo: () => set((state) => ({ ...state, to: null })),
}));

export default useLoginRedirectStore;
