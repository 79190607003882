/** @format */

import { HeadersDefaults } from "axios";
import debugLib from "debug";
import { isBoolean } from "lodash";
import qs from "qs";

import ApiAdapter from "./ApiAdapter";
import { RequestAdapterInterface } from "./RequestAdapterInterface";

const debug = debugLib("auth:adapter:auth");

type LoginResponse = {
    success: boolean;
    token: string | undefined;
};

export interface CommonHeaderProperties extends HeadersDefaults {
    common: {
        Authentication?: string;
        Authorization?: string;
        Auth?: string;
    };
}

export interface AuthResponseType {
    authenticated: boolean;
    error: AuthResponseType | null;
}

export default class AuthAdapter<
    RequestConfigType = unknown
> extends ApiAdapter<RequestConfigType> {
    public constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter);
    }

    public async login(username: string, password: string): Promise<boolean> {
        const {
            data: { success, token },
        } = await this._post<LoginResponse>(
            "core/users/login",
            qs.stringify({
                username,
                password,
                remember_me: "on",
            }),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );

        if (!success) {
            return false;
        }

        if (!token) {
            debug("Server did not return api token");
        }

        return success;
    }

    public async logout(): Promise<void> {
        await this._get("core/users/logout");
    }

    public async checkLogin(): Promise<AuthResponseType> {
        try {
            const { data } = await this._get<boolean>("core/users/loggedin");

            if (!isBoolean(data)) {
                return {
                    authenticated: false,
                    error: null,
                };
            }

            return {
                authenticated: data,
                error: null,
            };
        } catch (error: unknown) {
            return {
                authenticated: false,
                error: error as AuthResponseType,
            };
        }
    }
}
