/** @format */

export interface PaginatorParams {
    pageIndex: number;
    pageSize: number;
    search?: string;
    sort?: string;
    direction?: boolean;
    filter?: string;
}

export const fnPaginationParams = (
    paginator?: PaginatorParams
): {
    params: string;
} => {
    let params = "";

    if (paginator && paginator?.search && paginator.search !== "") {
        params += `?search=${paginator.search}`;
    } else {
        params += "?search=";
    }

    if (paginator) {
        params += `&page=${paginator?.pageIndex}&limit=${paginator?.pageSize}`;
    }

    if (paginator && paginator.sort && paginator.sort !== "") {
        params += `&sort=${paginator.sort}&direction=${paginator.direction ? "desc" : "asc"}`;
    }

    if (paginator && paginator.filter && paginator.filter !== "") {
        params += `&filter=${paginator.filter}`;
    }

    return {
        params,
    };
};
