/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { UserModel } from "@api/auth/UserAdapter";
import { DefaultPaginationRequestData } from "@components/Table/TableBuilder";
import { fnPaginationParams, PaginatorParams } from "@hook/usePaginationParams";
import { UploadImage } from "@typings/UploadTypes";
import { DateTime } from "luxon";

import { ModelSearchResponse } from "./ApparatusAdapter";
import { CompanyModel } from "./CompanyAdapter";
import {
    ChangeCostcenterUserAssignmentMode,
    ChangeCostcenterUserNotificationMode,
    CostcenterModel,
    CostcenterUserEntry,
    FetchCostcenterUserMode,
} from "./CostcenterAdapter";
import { Group } from "./GroupAdapter";
import { MandantorModel } from "./MandantorAdapter";

export interface UserCostcenter extends Omit<CostcenterModel, "id"> {
    id: number;
    costcenters_users: CostcenterUserEntry[];
}

export interface ExtendedUserModel extends UserModel {
    uploads?: UploadImage[];
    users_online: boolean;
    last_invited: DateTime | null;
    archived: DateTime | null;
    pending_email_change: string | null;
    prefix: string;
    fullname: string;
    salutation: string;
    company: CompanyModel;
}

export interface ExtendedUserModelPaginator extends Paginator {
    users: ExtendedUserModel[];
}

export interface UserNotes {
    author: string;
    created: string;
    note: string;
}

export interface UserView extends Omit<UserModel, "id"> {
    id: number;
    uploads?: UploadImage[];
    last_invited: string | null;
    archived: string | null;
    company: CompanyModel;
    mandantor?: MandantorModel;
    group: Group;
}

export interface UserSearchModel {
    id: number;
    full: string;
    name: string;
    surname: string;
    uploads: {
        id: number;
        url: string;
    }[];
    is_admin: boolean;
    is_user: boolean;
    is_viewer: boolean;
    archived?: boolean;
}

export interface UserSelectedModel extends UserSearchModel {
    active: boolean;
}

export interface UserAdd {
    id?: number;
    name: string;
    surname: string;
    email: string;
    username: string;
    group_id: number;
    sex: string;
    lang?: string;
    enabled?: boolean;
    reminds?: boolean;
    company_id?: number;
    role_id?: number;
    lastlogin?: boolean;
}

export interface TableUserData extends Model {
    user_id: number;
    table_token: string;
    data: string;
}

export interface TableInfoData {
    singleUserTable?: TableUserData;
    customFields?: {
        [key: number]: {
            id: number;
            title: string;
            form_type: string;
        };
    };
}

export interface ExtendedUserChose {
    user: UserAdd;
    groups: {
        [id: number]: string;
    };
    companies: {
        [id: number]: string;
    };
}

export interface ExtendedResponseError {
    [model: string]: {
        [field: string]: {
            [error_code: string]: string;
        };
    };
}

export interface ExtendedUserSend {
    user: UserAdd;
    success: boolean;
    errors?: ExtendedResponseError;
}

export interface ExtendedUserAdd extends UserAdd {
    id?: number;
}

export interface ExtendedOwnerSignup {
    user: {
        name: string;
        surname: string;
        email: string;
        username: string;
        sex: string;
        password: string;
        password_again: string;
        privdisaccept: boolean;
    };
    mandantor: {
        name: string;
    };
    success?: boolean;
    errors?: ExtendedResponseError;
}

export default class ExtendedUserAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    ExtendedUserModel,
    ExtendedUserModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("extended-users", "extended-user", "extended-users", adapter);
    }

    public async getIndex(
        archived = false,
        paginator?: PaginatorParams
    ): Promise<DefaultPaginationRequestData<ExtendedUserModel>> {
        const { params } = fnPaginationParams(paginator);

        const { data } = await this._get<DefaultPaginationRequestData<ExtendedUserModel>>(
            `index${params}&archived=${String(archived)}`
        );

        return data;
    }

    public async getAddUser(id: string | undefined): Promise<ExtendedUserChose> {
        const url = id ? `edit/${id}` : "add";
        const { data } = await this._get<ExtendedUserChose>(`${url}`);

        return data;
    }

    public async addUser(
        user: ExtendedUserAdd,
        image: File | null,
        sendInvite?: boolean
    ): Promise<ExtendedUserSend> {
        const formData = new FormData();

        Object.keys(user).map((key) => {
            const keyType = key as keyof ExtendedUserAdd;

            formData.append(key, String(user[keyType]));
        });

        if (sendInvite) {
            formData.append("sendInvite", String(sendInvite));
        }

        if (image) {
            formData.append("uploads[file]", image);
        }
        const { data } = await this._post<ExtendedUserSend>(`add`, formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data;
    }

    public async editUser(
        user: ExtendedUserAdd,
        id: string,
        sendInvite?: boolean
    ): Promise<ExtendedUserSend> {
        const { data } = await this._post<ExtendedUserSend>(`edit/${id}`, {
            ...user,
            sendInvite,
        });

        return data;
    }

    public async getSearch(search = ""): Promise<ModelSearchResponse> {
        const { data } = await this._get<ModelSearchResponse>(`getGlobalSearch?search=${search}`);

        return data;
    }

    public async signupOwner(saveData: ExtendedOwnerSignup): Promise<ExtendedOwnerSignup> {
        const { user, mandantor } = saveData;

        const { data } = await this._post<ExtendedOwnerSignup>("signup-owner", {
            user,
            mandantor,
        });

        return data;
    }

    public async getUser(id: number): Promise<UserView | null> {
        const { data } = await this._get<{ user: UserView | null }>(`view/${id}`);
        if (!data) {
            return null;
        }
        return data.user;
    }

    // @todo cann be changed
    public async profileImage(): Promise<UploadImage> {
        const { data } = await this._get<{ upload: UploadImage }>(`profileImage`);

        return data.upload;
    }

    public async searchUsers(search = "", onlyClients = false): Promise<UserSearchModel[]> {
        const { data } = await this._get<{ users: UserSearchModel[] }>(
            `searchUsers?search=${search}&onlyClients=${String(onlyClients)}`
        );

        return data.users;
    }

    public async getUserCount(): Promise<number> {
        const { data } = await this._get<{ count: number }>(`get-user-count`);

        return data.count;
    }

    public async addTableInfo(token: string, dataTable: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>("addTableInfo", {
            token,
            dataTable,
        });

        return data.success;
    }

    public async getTableInfo(token: string, key?: string): Promise<TableInfoData> {
        const postkey = key ?? "";
        const { data } = await this._get<TableInfoData>(`getTableInfo/${token}/${postkey}`);

        return data;
    }

    public async getCostcenters(
        userId: number,
        mode: FetchCostcenterUserMode,
        paginator?: PaginatorParams
    ): Promise<DefaultPaginationRequestData<UserCostcenter>> {
        const { params } = fnPaginationParams(paginator);

        const { data } = await this._get<DefaultPaginationRequestData<UserCostcenter>>(
            `getCostcenters/${userId}/${mode}${params}`
        );

        return data;
    }

    public async changeUserCostcenterAssignment(
        userId: number,
        costcenterIds: number[],
        mode: ChangeCostcenterUserAssignmentMode
    ): Promise<UserView> {
        const { data } = await this._post<{ user: UserView }>(
            `changeCostcentersAssignments/${userId}/${mode}`,
            {
                costcenter_ids: costcenterIds,
            }
        );

        return data.user;
    }

    public async changeCostcentersNotifications(
        userId: number,
        costcenterIds: number[],
        mode: ChangeCostcenterUserNotificationMode
    ): Promise<UserView> {
        const { data } = await this._post<{ user: UserView }>(
            `changeCostcentersNotifications/${userId}/${mode}`,
            {
                costcenter_ids: costcenterIds,
            }
        );

        return data.user;
    }
}
