/** @format */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { CompanyAttribute, MandantorAttribute, UserAttribute } from "@api/ext/AttributeAdapter";
import { AuthUserModel } from "@api/ext/GlobalUserAdapter";
import { SubscriptionRights } from "@api/ext/SubscriptionAdapter";

// import debugLib from "debug";

import ControllerAdapter, {
    Model,
    ModelWithId,
    PaginationParams,
    Paginator,
} from "./ControllerAdapter";
import { RequestAdapterInterface } from "./RequestAdapterInterface";

// const debug = debugLib("prima:core:adapter:user");

export interface UserModel extends Model {
    id?: number;
    uuid?: string;
    group_id?: number;
    mandantor_id?: number;
    company_id?: number;
    role_id?: number;
    is_admin?: boolean;
    username: string;
    sex: "d" | "f" | "m";
    title?: string;
    name: string;
    surname: string;
    email: string;
    lang: string;
    reminds: number;
    enabled: boolean;
    privdisaccept: boolean;
    mustchpwd: boolean;
    lastlogin: string | null;
    failedlogins: number;
    emailconfirmed: boolean;
    fullname?: string;
    onboarding: string | null;
    users_attributes: UserAttribute;
    company_attributes: CompanyAttribute;
    mandantor_attributes: MandantorAttribute;
    subscription_rights: SubscriptionRights;
    info: {
        costcenter_num: number;
        apparatuses_num: number;
        users_num: number;
    };
    avatar: string | null;
}

export interface UserAddModel extends Partial<UserModel> {
    role_id: number;
    sex: UserModel["sex"];
    username: string;
    title?: string;
    name: string;
    email: string;
    lang: string;
    classifications?: {
        _ids: number[];
    };
}

export interface UserPaginator extends Paginator {
    users: UserModel[];
}

export default class UserAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    AuthUserModel,
    UserPaginator,
    UserAddModel
> {
    public constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("core/users", "user", "users", adapter, {
            dateFields: ["created", "modified", "lastlogin"],
        });
    }

    public async whoami(signal?: AbortSignal | undefined): Promise<ModelWithId<AuthUserModel>> {
        const { data } = await this._get<ModelWithId<AuthUserModel>>("whoami", { config: signal });

        return this.hydrateEntity(data);
    }

    public async search(
        search?: string,
        paginationParams?: PaginationParams
    ): Promise<UserPaginator> {
        const {
            data: {
                users,
                paginator: { Users: paginator },
            },
        } = await this.paginate("search", paginationParams, {
            params: {
                search,
            },
        });

        return {
            users,
            paginator,
        };
    }
}
