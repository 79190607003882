/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { UserModel } from "@api/auth/UserAdapter";
import { DefaultPaginationRequestData } from "@components/Table/TableBuilder";
import { fnPaginationParams, PaginatorParams } from "@hook/usePaginationParams";
import { ModelImage } from "@typings/UploadTypes";

import { ApparatusIndexModel, ModelSearchResponse } from "./ApparatusAdapter";
import { CompanyModel } from "./CompanyAdapter";
import { MandantorModel } from "./MandantorAdapter";

export type FetchCostcenterUserMode = "all" | "assigned" | "unassigned";
export type FetchCostcenterListMode = "withoutNotification";
export type ChangeCostcenterUserAssignmentMode = "assign" | "unassign";
export type ChangeCostcenterUserNotificationMode = "notify" | "unnotify";

export interface CostcenterUserEntry {
    id: number;
    notify: boolean;
    user_id: UserModel["id"];
}
export interface CostcenterUser {
    id: number;
    company_id: number | null;
    name: string;
    surname: string;
    fullname: string;
    email: string;
    mandantor: {
        id: number;
        name: string;
    };
    company: {
        id: number;
        name: string;
    };
    costcenters_users: CostcenterUserEntry[];
}
export interface CostcenterUserList extends CostcenterUser {
    img?: ModelImage;
    avatar?: string;
}
interface ExtendedCostercenterUserModel extends UserModel {
    img?: ModelImage;
}

export interface CostcenterModel extends Model {
    company_id: number;
    uuid: string;
    name: string;
    description?: string;
    count_apparatuses: number;
    count_observed_apparatuses: number;
    count_users: number;
    count_reminder_users: number;
    notify_me: boolean | null;
    mandantor?: MandantorModel;
    company?: CompanyModel;
    users?: ExtendedCostercenterUserModel[];
    notified_users?: number;
}
export interface CostcenterList {
    id: number;
    company_id: number;
    name: string;
    description: string | null;
}

export interface CostcenterModelPaginator extends Paginator {
    costcenters: CostcenterModel[];
}

export interface CostcenterPostForm {
    success: boolean;
    costcenter?: CostcenterModel;
    errors?: string[];
}

export default class CostcenterAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    CostcenterModel,
    CostcenterModelPaginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("costcenters", "costcenter", "costcenters", adapter);
    }

    public async index(
        paginator?: PaginatorParams
    ): Promise<DefaultPaginationRequestData<CostcenterModel>> {
        const { params } = fnPaginationParams(paginator);
        const { data } = await this._get<DefaultPaginationRequestData<CostcenterModel>>(
            `index${params}`
        );

        return data;
    }

    public async view(id: number): Promise<CostcenterModel> {
        const { data } = await this._get<{ costcenter: CostcenterModel }>(`view/${id}`);

        return data.costcenter;
    }

    public async getUsers(
        costcenterId: number,
        mode: FetchCostcenterUserMode,
        paginator?: PaginatorParams
    ): Promise<DefaultPaginationRequestData<CostcenterUserList>> {
        const { params } = fnPaginationParams(paginator);

        const { data } = await this._get<DefaultPaginationRequestData<CostcenterUserList>>(
            `getUsers/${costcenterId}/${mode}${params}`
        );

        return data;
    }

    public async getUsersList(
        costcenterId: number
    ): Promise<DefaultPaginationRequestData<CostcenterUserList>> {
        const { data } = await this._get<DefaultPaginationRequestData<CostcenterUserList>>(
            `getUsersList/${costcenterId}`
        );

        return data;
    }

    public async getApparatuses(
        costcenterId: number,
        paginator?: PaginatorParams,
        mode?: string
    ): Promise<DefaultPaginationRequestData<ApparatusIndexModel>> {
        const { params } = fnPaginationParams(paginator);

        const { data } = await this._get<DefaultPaginationRequestData<ApparatusIndexModel>>(
            `getApparatuses/${costcenterId}/${mode || "assigned"}${params}`
        );

        return data;
    }

    public async addCostcenter(
        name = "",
        description: string,
        notify?: boolean,
        companieId?: number
    ): Promise<CostcenterPostForm> {
        const { data } = await this._post<CostcenterPostForm>(`add`, {
            name,
            description: description || null,
            companieId,
            notify,
        });

        return data;
    }

    public async getSearch(search = ""): Promise<ModelSearchResponse> {
        const { data } = await this._get<ModelSearchResponse>(`getGlobalSearch?search=${search}`);

        return data;
    }

    public async editCostcenter(
        id: number,
        name: string,
        description: string
    ): Promise<CostcenterPostForm> {
        const { data } = await this._post<CostcenterPostForm>(`edit/${id}`, {
            name,
            description: description || null,
        });

        return data;
    }

    public async deleteCostcenter(id: number): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`delete/${id}`);

        return data.success;
    }

    public async getIndexList(mode?: FetchCostcenterListMode): Promise<CostcenterList[]> {
        const { data } = await this._get<{ costcenters: CostcenterList[] }>(
            `getIndex${mode ? `/${mode}` : ""}`
        );

        return data.costcenters;
    }

    public async changeCostcenterUserAssignment(
        costcenterId: number,
        userIds: number[],
        mode: ChangeCostcenterUserAssignmentMode
    ): Promise<CostcenterUser[]> {
        const { data } = await this._post<{ users: CostcenterUser[] }>(
            `changeUsersAssignments/${costcenterId}/${mode}`,
            {
                user_ids: userIds,
            }
        );

        return data.users;
    }

    public async changeCostcenterUserNotification(
        costcenterId: number,
        userIds: number[],
        mode: ChangeCostcenterUserNotificationMode
    ): Promise<CostcenterUser[]> {
        const { data } = await this._post<{ users: CostcenterUser[] }>(
            `changeUsersNotifications/${costcenterId}/${mode}`,
            {
                user_ids: userIds,
            }
        );

        return data.users;
    }
}
