/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

import defaultColors from "../defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Link: ComponentStyleConfig = {
    // The styles all button have in common
    baseStyle: ({ colorMode, colorScheme }) => ({
        color: `links.${colorScheme}.${colorMode}.color`,
        _disabled: {
            bg: defaultColors.grey[90],
            pointerEvents: "none",
            _hover: {
                bg: defaultColors.grey[90],
            },
        },
        _active: {
            outline: "none",
            boxShadow: "none",
        },
        _focus: {
            outline: "none",
            boxShadow: "none",
        },
    }),
    // The default size and variant values
    defaultProps: {
        colorScheme: "default",
    },
};

export const linkColors = {
    default: {
        light: {
            color: defaultColors.blue[100],
        },
        dark: {
            color: defaultColors.blue[60],
        },
    },
    success: {
        light: {
            color: defaultColors.green[100],
        },
        dark: {
            color: defaultColors.green[100],
        },
    },
    warning: {
        light: {
            color: defaultColors.yellow[100],
        },
        dark: {
            color: defaultColors.yellow[100],
        },
    },
    danger: {
        light: {
            color: defaultColors.red[100],
        },
        dark: {
            color: defaultColors.red[60],
        },
    },
    info: {
        light: {
            color: defaultColors.blue[100],
        },
        dark: {
            color: defaultColors.blue[60],
        },
    },
    irrelevant: {
        light: {
            color: defaultColors.grey[30],
        },
        dark: {
            color: defaultColors.grey[90],
        },
    },
};

export default Link;
