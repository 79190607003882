/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Divider: ComponentStyleConfig = {
    sizes: {
        md: {
            borderBottomWidth: 2,
        },
        xl: {
            borderBottomWidth: 3,
        },
    },
    defaultProps: {
        size: "md",
        variant: "solid",
    },
};

export default Divider;
