/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export interface ApparatusCommentModel extends Model {
    comment: string;
    user_id: number;
    user_img?: string;
    user_name: string;
}

export default class ApparatusCommentAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("ApparatusesComments", "ApparatusesComment", "ApparatusesComments", adapter);
    }

    public async addComment(id: number, comment: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`addComment`, {
            comment,
            apparatus_id: id,
        });

        return data.success;
    }

    public async changeComment(id: number, comment: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`changeComment/${id}`, {
            comment,
        });

        return data.success;
    }

    public async deleteComment(id: number): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`delete/${id}`);

        return data.success;
    }
}
