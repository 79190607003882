/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

import defaultColors from "../defaultColors";

const Table: ComponentStyleConfig = {
    parts: ["table", "thead", "tbody", "tr", "th", "td", "tfoot", "caption"],
    baseStyle: ({ colorMode }) => ({
        table: {},
        thead: {
            bg: "transparent",
            tr: {
                bg: "transparent",
            },
        },
        tbody: {},
        tr: {},
        td: {
            px: 2,
            py: 1,
        },
        th: {
            fontWeight: "normal",
            textTransform: "none",
            p: 2,
            bg: `tables.th.${colorMode}.bg`,
            borderLeft: "2px solid white",
            borderRight: "2px solid white",
            boxShadow: `inset 0 -3px 0 ${defaultColors.orange[100]}`,
            letterSpacing: "tighter",
            _first: {
                borderLeft: "none",
            },
            _last: {
                borderRight: "none",
            },
        },
        tfoot: {},
        caption: {},
    }),
    variants: {
        memida: ({ colorMode }) => ({
            tr: {
                _even: {
                    background: `tables.tr.${colorMode}.bg`,
                },
            },
        }),
    },
    defaultProps: {},
};

export const tableColors = {
    th: {
        light: {
            bg: defaultColors.grey[20],
            color: defaultColors.black[100],
            border: defaultColors.orange[100],
            hover: {
                bg: defaultColors.grey[30],
                color: defaultColors.white,
            },
        },
        dark: {
            bg: defaultColors.grey[90],
            color: defaultColors.white,
            border: defaultColors.orange[100],
            hover: {
                bg: defaultColors.grey[10],
                color: defaultColors.white,
            },
        },
    },
    tr: {
        light: {
            bg: defaultColors.grey[10],
            color: defaultColors.black[100],
            hover: {
                bg: defaultColors.grey[30],
                color: defaultColors.white,
            },
        },
        dark: {
            bg: defaultColors.grey[90],
            color: defaultColors.white,
            hover: {
                bg: defaultColors.grey[10],
                color: defaultColors.white,
            },
        },
    },
};

export default Table;
