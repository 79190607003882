/** @format */

import { apparatusAdapter, subscriptionAdapter } from "@api/ApiRequest";
import { AuthUserModel } from "@api/ext/GlobalUserAdapter";
import { SubscriptionInfo } from "@api/ext/SubscriptionAdapter";
import { useColorMode } from "@chakra-ui/react";
import usePageTitleStore from "@store/usePageTitleStore";
import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { History } from "history";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "../hook/useAuth";
import { Connection } from "../services/Connection";

interface HistoryStackInterface {
    info: string;
    link: string;
    rdy: boolean;
}

const defaultValue: {
    authentication: boolean | null;
    user: AuthUserModel | undefined;
    isAdmin: boolean;
    isAdminOrUser: boolean;
    refetchUser: () => void;
    logout: () => void;
    validateAuth: () => void;
    connected: boolean;
    historyStack: HistoryStackInterface[];
    refetchSubscription: () => Promise<QueryObserverResult<SubscriptionInfo, Error> | void>;
    removeSubscription: () => void;
    subscription?: SubscriptionInfo | null;
    apparatusCount: number;
    refetchApparatusCount: () => void;
} = {
    authentication: null,
    user: undefined,
    isAdmin: false,
    isAdminOrUser: false,
    refetchUser: () => null,
    logout: () => null,
    validateAuth: () => null,
    connected: true,
    historyStack: [],
    refetchSubscription: () => Promise.resolve(),
    removeSubscription: () => null,
    subscription: undefined,
    apparatusCount: 0,
    refetchApparatusCount: () => null,
};

const AuthContext = React.createContext(defaultValue);

interface AuthProviderProps {
    history: History;
    children?: React.ReactNode;
}

const fetchSubscription = async () => await subscriptionAdapter.info();
const fetchApparatusCount = async () => await apparatusAdapter.getCount();

let unlisten = (): void | null => null;

const AuthProvider: React.FC<AuthProviderProps> = ({ children, history }) => {
    const [authentication, user, refetchUser, doLogout, validateAuth] = useAuth();

    const { title, info } = usePageTitleStore();
    const { setColorMode } = useColorMode();

    const [connected, setConnected] = useState<boolean>(true);

    const locationRoute = useLocation();

    const [historyStack, setHistoryStack] = useState<HistoryStackInterface[]>([]);

    const isAdminOrUser = user ? user.is_admin || user.is_user : false;
    const isAdmin = user?.is_admin ? user.is_admin : false;

    const { data: apparatusCount = 0, refetch: refetchApparatusCount } = useQuery(
        ["apparatusCount"],
        fetchApparatusCount,
        {
            enabled: false,
        }
    );

    const {
        data: subscription,
        refetch: refetchSubscription,
        remove: removeSubscription,
    } = useQuery(["subscription"], fetchSubscription, {
        enabled: false,
        placeholderData: undefined,
        onError(err: Error) {
            if (err.message === "invalid response") {
                setTimeout(() => {
                    void refetchSubscription();
                }, 5000);
            }
        },
    });

    const getCurrTitle = useCallback(() => {
        return info ? `${title} ${info}` : title;
    }, [info, title]);

    useEffect(() => {
        unlisten();
        unlisten = history.listen(({ action, location }) => {
            if (action !== "PUSH") {
                return;
            }

            setHistoryStack((old) => {
                if (old.length >= 5) {
                    old.shift();
                }

                const updateHistory = `${location.pathname}${location.search}${location.hash}`;
                const info = getCurrTitle();

                if (updateHistory === "/") {
                    return old;
                }

                if (old.length === 0) {
                    return [{ link: updateHistory, info: "", rdy: false }];
                }

                if (old.length >= 1) {
                    const getIndex = old.findIndex((value) => value.link === updateHistory);

                    if (getIndex !== -1) {
                        old.splice(getIndex, 1);

                        const updatedOld = [...old];
                        if (updatedOld && updatedOld[0]) {
                            updatedOld[0].info = info;
                            updatedOld[0].rdy = true;
                        }

                        return [{ link: updateHistory, info: "", rdy: false }, ...updatedOld];
                    }

                    if (updateHistory === old[old.length - 1].link) {
                        return old;
                    }

                    const updatedOld = [...old];
                    updatedOld[0].info = info;
                    updatedOld[0].rdy = true;

                    return [{ link: updateHistory, info: "", rdy: false }, ...old];
                }

                return [];
            });
        });

        return () => {
            unlisten();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCurrTitle]);

    useEffect(() => {
        if (authentication && user) {
            void setColorMode(
                user.users_attributes ? user.users_attributes.color_mode ?? "light" : "light"
            );

            if (!user.is_admin && !user.is_user) {
                return;
            }

            void refetchSubscription();
            void refetchApparatusCount();
        }
        // only update attributes and subscription when user changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleLogout = useCallback(() => {
        void removeSubscription();
        void doLogout();
    }, [doLogout, removeSubscription]);

    useEffect(() => {
        Connection.unsubscribe();
        setConnected(true);

        Connection.subscribe({
            connected: true,
            event(data: boolean) {
                setConnected((preState) => {
                    if (!preState) {
                        return preState;
                    }

                    return data;
                });
            },
        });

        return () => {
            Connection.unsubscribe();
        };
    }, [locationRoute]);

    const providerValue = {
        authentication,
        user,
        isAdmin,
        isAdminOrUser,
        logout: handleLogout,
        refetchUser,
        validateAuth,
        connected,
        historyStack,
        subscription,
        refetchSubscription,
        removeSubscription,
        apparatusCount,
        refetchApparatusCount,
    };

    return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
export default AuthContext;
