/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";
import defaultColors from "@theme/defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Modal: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        dialog: {
            backgroundColor:
                colorMode === "light" ? defaultColors.grey.light : defaultColors.grey.dark,
        },
        header: {
            p: { base: 2, md: 3, "2xl": 5 },
        },
        body: {
            p: { base: 2, md: 3, "2xl": 5 },
        },
        footer: {
            p: { base: 2, md: 3, "2xl": 5 },
        },
        overlay: {
            backgroundColor: "blackAlpha.300",
            backdropFilter: "blur(10px) grayscale(60%)",
        },
    }),
};

export default Modal;
