/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

import defaultColors from "../defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Button: ComponentStyleConfig = {
    // The styles all button have in common
    baseStyle: ({ colorMode }) => ({
        fontWeight: "bold",
        textTransform: "uppercase",
        borderRadius: "base",
        letterSpacing: "tighter",
        _disabled: {
            bg: defaultColors.grey[90],
            border: "none",
            pointerEvents: "none",
            _hover: {
                bg: defaultColors.grey[90],
                cursor: "not-allowed",
            },
        },
        _active: {
            outline: "none",
            boxShadow: "none",
        },
        _focus: {
            outline: "none",
            boxShadow: "none",
        },
    }),
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: "sm",
            px: 4,
            py: 3,
        },
        md: {
            fontSize: "md",
            px: 6,
            py: 4,
        },
    },
    // Two variants: outline and solid
    variants: {
        input: ({ colorMode, colorScheme }) => ({
            border: "2px solid transparent",
            color: `buttons.${colorScheme}.${colorMode}.bg`,
            borderColor: `buttons.${colorScheme}.${colorMode}.bg`,
            _hover: {
                color: `buttons.${colorScheme}.${colorMode}.color`,
                bg: `buttons.${colorScheme}.${colorMode}.bg`,
            },
            _disabled: {
                color: `buttons.${colorScheme}.${colorMode}.color`,
                bg: `buttons.${colorScheme}.${colorMode}.bg`,
                opacity: 0.85,
                pointerEvents: "none",
            },
        }),
        outline: ({ colorMode, colorScheme }) => ({
            border: "2px solid transparent",
            color: `buttons.${colorScheme}.${colorMode}.bg`,
            borderColor: `buttons.${colorScheme}.${colorMode}.bg`,
            _hover: {
                color: `buttons.${colorScheme}.${colorMode}.color`,
                bg: `buttons.${colorScheme}.${colorMode}.bg`,
            },
            _disabled: {
                color: `buttons.${colorScheme}.${colorMode}.color`,
                opacity: 0.85,
                pointerEvents: "none",
            },
        }),
        solid: ({ colorMode, colorScheme }) => ({
            color: `buttons.${colorScheme}.${colorMode}.color`,
            bg: `buttons.${colorScheme}.${colorMode}.bg`,
            _hover: {
                bg: `buttons.${colorScheme}.${colorMode}.hover.bg`,
                color: `buttons.${colorScheme}.${colorMode}.hover.color`,
            },
            _disabled: {
                color: `buttons.${colorScheme}.${colorMode}.color`,
                bg: `buttons.${colorScheme}.${colorMode}.bg`,
                opacity: 0.5,
                pointerEvents: "none",
            },
        }),
        ghost: ({ colorMode, colorScheme }) => ({
            fontWeight: "normal",
            color: `buttons.${colorScheme}.${colorMode}.bg`,
            bg: "transparent",
            _hover: {
                color: `buttons.${colorScheme}.${colorMode}.hover.bg`,
                bg: "transparent",
            },
            _active: {
                color: `buttons.${colorScheme}.${colorMode}.hover.bg`,
                bg: "transparent",
            },
            _disabled: {
                color: `buttons.${colorScheme}.${colorMode}.color`,
                bg: `buttons.${colorScheme}.${colorMode}.bg`,
                opacity: 0.85,
                pointerEvents: "none",
            },
        }),
        link: ({ colorMode, colorScheme }) => ({
            fontWeight: "normal",
            color: `buttons.${colorScheme}.${colorMode}.bg`,
            bg: "transparent",
            textTransform: "none",
            _hover: {
                color: `buttons.${colorScheme}.${colorMode}.hover.bg`,
                textDecoration: "underline",
            },
            _disabled: {
                color: `buttons.${colorScheme}.${colorMode}.color`,
                bg: `buttons.${colorScheme}.${colorMode}.bg`,
                opacity: 0.85,
                pointerEvents: "none",
            },
        }),
    },
    // The default size and variant values
    defaultProps: {
        size: "md",
        variant: "solid",
        colorScheme: "default",
    },
};

export const buttonColors = {
    default: {
        light: {
            bg: defaultColors.green[100],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.blue[100],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.blue[100],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.green[60],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.green[100],
                color: defaultColors.yellow,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.blue[100],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
    success: {
        light: {
            bg: defaultColors.green[100],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.green[60],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.green[60],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.green[60],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.green[100],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.green[100],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
    warning: {
        light: {
            bg: defaultColors.yellow[100],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.yellow[100],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.yellow[100],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.yellow[100],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.yellow[100],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.yellow[100],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
    danger: {
        light: {
            bg: defaultColors.red[100],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.red[60],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.red[60],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.red[60],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.red[100],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.red[100],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
    info: {
        light: {
            bg: defaultColors.blue[100],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.blue[60],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.blue[60],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.blue[60],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.blue[100],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.blue[100],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
    irrelevant: {
        light: {
            bg: defaultColors.grey[40],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.grey[30],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.grey[90],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.grey[70],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.grey[80],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.grey[40],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
    action: {
        light: {
            bg: defaultColors.grey[20],
            color: defaultColors.grey[100],
            border: "transparent",
            hover: {
                bg: defaultColors.grey[25],
                color: defaultColors.grey[100],
                border: "transparent",
            },
            focus: {
                bg: defaultColors.grey[90],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.grey[100],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.grey[80],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.grey[40],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
    light: {
        light: {
            bg: defaultColors.grey[20],
            color: defaultColors.grey[80],
            border: "transparent",
            hover: {
                bg: defaultColors.grey[20],
                color: defaultColors.grey[80],
                border: "transparent",
            },
            focus: {
                bg: defaultColors.grey[30],
                color: defaultColors.white,
                border: "transparent",
            },
        },
        dark: {
            bg: defaultColors.grey[70],
            color: defaultColors.white,
            border: "transparent",
            hover: {
                bg: defaultColors.grey[80],
                color: defaultColors.white,
                border: "transparent",
            },
            focus: {
                bg: defaultColors.grey[90],
                color: defaultColors.white,
                border: "transparent",
            },
        },
    },
};

export default Button;
