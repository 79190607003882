/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export interface CustomFieldContentAdd {
    id: number;
    model: string;
    app_id: number;
    value: string | number | Date;
}

export default class CustomFieldContentAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("customFieldContents", "customFieldContent", "customFieldContents", adapter);
    }

    public async updateField(form: CustomFieldContentAdd): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(`update`, form);

        return data.success;
    }
}
