/** @format */

class ConnectionObserver {
    private connected: { connected: boolean; event: (update: boolean) => void } | null;

    constructor() {
        this.connected = null;
    }

    subscribe(change: { connected: boolean; event: (update: boolean) => void }) {
        this.connected = change;
    }

    unsubscribe() {
        this.connected = null;
    }

    setReachable(connected: boolean) {
        if (this.connected !== null) {
            this.connected.event(connected);
        }
    }
}

export const Connection = new ConnectionObserver();
