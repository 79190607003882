/** @format */

import { Flex, Spinner } from "@chakra-ui/react";
import defaultColors from "@theme/defaultColors";
import React from "react";

const SuspenseLoad: React.FC = () => {
    return (
        <Flex w="full" h="full" justifyContent="center" alignItems="center">
            <Spinner color={defaultColors.blue[30]} />
        </Flex>
    );
};

export default SuspenseLoad;
