/** @format */
import { ChakraProvider } from "@chakra-ui/react";
import PopUpLoading from "@components/PopUpAlert/Loading/PopUpLoading";
import PopUp from "@components/PopUpAlert/Save/PopUp";
import SuspenseLoad from "@components/SuspenseLoad/SuspenseLoad";
import { LoadingProvider } from "@context/LoadingProvider";
import useLoginRedirectStore from "@store/useLoginRedirectStore";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createBrowserHistory } from "history";
import { Settings } from "luxon";
import React, { lazy, Suspense, useContext, useEffect } from "react";
import {
    Routes,
    Route,
    useLocation,
    unstable_HistoryRouter as HistoryRouter,
    useNavigate,
} from "react-router-dom";

import AuthContext, { AuthProvider } from "./context/AuthProvider";
import theme from "./theme/theme";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";

import "./services/i18n";

import "./App.scss";

const MainScreen = lazy(() => import("./router/MainRouter"));
const UnauthenticatedRouter = lazy(() => import("./router/UnauthenticatedRouter"));

const history = createBrowserHistory({ window });

let timeoutId: ReturnType<typeof setTimeout>;

const MainRouter: React.FC = () => {
    const { authentication, connected } = useContext(AuthContext);
    const { setTo } = useLoginRedirectStore();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setTo(location.pathname + location.search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!connected && authentication && location.pathname !== "/connected") {
            timeoutId = setTimeout(() => {
                navigate("/connected", {
                    state: { backgroundLocation: location },
                });
            }, 3000);
        } else if (connected) {
            clearTimeout(timeoutId);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [connected, location, navigate, authentication]);

    if (authentication === null) {
        return null;
    }

    return (
        <Suspense fallback={<SuspenseLoad />}>
            <Routes>
                {authentication ? (
                    <Route path="*" element={<MainScreen />} />
                ) : (
                    <Route path="*" element={<UnauthenticatedRouter />} />
                )}
            </Routes>
        </Suspense>
    );
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
        },
    },
});

const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
    key: "MEMIDA_SYNC",
});

persistQueryClient({
    queryClient,
    persister: localStoragePersister,
});

const App: React.FC = (): React.ReactElement => {
    Settings.defaultLocale = "de-DE";

    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <HistoryRouter history={history}>
                    <AuthProvider history={history}>
                        <LoadingProvider>
                            <MainRouter />

                            <PopUpLoading />
                            <PopUp />
                        </LoadingProvider>
                    </AuthProvider>
                </HistoryRouter>

                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
        </ChakraProvider>
    );
};
export default App;
