/** @format */

const defaultColors = {
    transparent: "transparent",
    white: "#fff",
    green: {
        100: "#7B904B",
        60: "#96AC5D",
        50: "rgb(123, 144, 75, 0.5)",
        30: "#CAD5AE",
    },
    red: {
        100: "#CA054D",
        60: "#FA3C8C",
        30: "#FDC3D9",
    },
    betterRed: {
        100: "#A34B4C",
        60: "#CC8F90",
    },
    blue: {
        100: "#247BA0",
        60: "#2D99C8",
        30: "#ACD9EC",
    },
    yellow: {
        100: "#FEC601",
        50: "#FEE071",
    },
    orange: {
        100: "#EE8434",
        60: "#ffba58",
        30: "#ffd9a5",
    },
    grey: {
        dark: "#2f2d2d",
        light: "#F5F5F4",
        100: "#444140",
        90: "#54504F",
        80: "#746F6D",
        70: "#9C9896",
        // TODO: need check
        // 60: "#444140
        50: "#C4C1C0",
        30: "#CFCFC9",
        25: "#E4E1E1",
        20: "#EBEAEA",
        10: "#F5F5F4",

        40: "#777769", // need check
        16: "#e4e1e1", // need check
        15: "#ededed", // need check
        5: "#f9f9f5", // need check
    },
    black: {
        100: "#201E1E",
        80: "#2A2828",
    },
    silver: {
        100: "#A9A99E",
    },
    input: {
        text: "#777769",
        bg: "#F5F5F4",
    },
};

export default defaultColors;
