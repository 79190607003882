/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";

import defaultColors from "../defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Menu: ComponentStyleConfig = {
    baseStyle: ({ colorMode, colorScheme }) => ({
        list: {
            zIndex: 10,
            bg: `menu.${colorMode}.bg`,
        },
        item: {
            bg: `menu.${colorMode}.bg`,
            _hover: {
                bg: `menu.${colorMode}.hover.bg`,
            },
        },
    }),
};

export const menuColors = {
    light: {
        color: defaultColors.grey[10],
        bg: defaultColors.white,
        hover: {
            color: defaultColors.blue[60],
            bg: defaultColors.grey[10],
        },
    },
    dark: {
        color: defaultColors.grey[20],
        bg: defaultColors.grey[100],
        hover: {
            color: defaultColors.grey[10],
            bg: defaultColors.grey[90],
        },
    },
};

export default Menu;
