/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { DateTime } from "luxon";

export interface ApiTokenModel {
    uuid: string;
    name: string;
    last_used: DateTime;
    created: DateTime;
    server?: string;
    company: {
        name: string;
    };
}

export interface ApiTokenResponse {
    success: boolean;
    token: string;
}

export interface DeafultResponseError {
    [key: string]: {
        [key: string]: string;
    };
}

export interface GenerateApiTokenResponse extends ApiTokenResponse {
    uuid: string;
    error?: DeafultResponseError;
}

export default class ApiAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("api", "api", "apis", adapter);
    }

    public async showTokens(): Promise<ApiTokenModel[]> {
        const { data } = await this._get<{ tokens: ApiTokenModel[] }>(``);

        return data.tokens;
    }

    public async generate(
        name: string,
        id: number,
        server?: string
    ): Promise<GenerateApiTokenResponse> {
        const { data } = await this._post<GenerateApiTokenResponse>(`generate`, {
            name,
            id,
            server,
        });

        return data;
    }

    public async resetToken(id: string): Promise<ApiTokenResponse> {
        const { data } = await this._get<ApiTokenResponse>(`reset/${id}`);

        return data;
    }

    public async removeToken(id: string): Promise<boolean> {
        const { data } = await this._delete<{ success: boolean }>(`delete/${id}`);

        return data.success;
    }
}
