/** @format */

import React, { useState, useEffect, useCallback } from "react";

export interface Notification {
    show: boolean;
    type?: "failed" | "successful";
    text?: string;
}

const defaultValue: {
    setLoading: (loading: boolean) => void;
    loading: boolean;

    setSaved: (saved: Notification) => void;
    saved: Notification;

    shouldRefetch: boolean;
    setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;

    handleClose: () => void;
} = {
    setLoading: () => null,
    loading: false,

    setSaved: () => null,
    saved: { show: false },

    shouldRefetch: false,
    setShouldRefetch: () => null,

    handleClose: () => null,
};

const LoadingContext = React.createContext(defaultValue);

let timeoutId: ReturnType<typeof setTimeout>;

interface LoadingProviderProps {
    children?: React.ReactNode;
}

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState<Notification>({ show: false });
    const [activePopu, setActivePopup] = useState<boolean>(false);
    const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

    useEffect(() => {
        if (!saved.show) {
            return;
        }

        timeoutId = setTimeout(() => {
            setSaved({
                ...saved,
                show: false,
            });

            setActivePopup(false);
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [saved]);

    const setSavedOverride = useCallback(
        (value: Notification) => {
            clearTimeout(timeoutId);

            setSaved(value);

            setActivePopup(true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setSaved, activePopu]
    );

    const handleClose = useCallback(() => {
        clearTimeout(timeoutId);
        setSaved({ ...saved, show: false });
    }, [setSaved, saved]);

    const providerValue = {
        loading,
        saved,
        setLoading,
        setSaved: setSavedOverride,
        handleClose,
        shouldRefetch,
        setShouldRefetch,
    };

    return <LoadingContext.Provider value={providerValue}>{children}</LoadingContext.Provider>;
};

export { LoadingProvider };
export default LoadingContext;
