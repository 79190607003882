/** @format */

export const defaultGap = {
    base: 2,
    md: 3,
    "2xl": 5,
};

export const defaultNegativeGap = {
    base: -2,
    md: -3,
    "2xl": -5,
};

export const defaultFontSizeHeading = {
    base: "xl",
    xl: "2xl",
};
