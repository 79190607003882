/** @format */

import RequestAdapter from "./RequestAdapter";
import AuthAdapter from "./auth/AuthAdapter";
import UserAdapter from "./auth/UserAdapter";
import ApiAdapter from "./ext/ApiAdapter";
import ApparatusAdapter from "./ext/ApparatusAdapter";
import ApparatusBasedataAdapter from "./ext/ApparatusBasedataAdapter";
import ApparatusCommentAdapter from "./ext/ApparatusCommentAdapter";
import ApparatusLocationAdapter from "./ext/ApparatusLocationAdapter";
import ApparatusLogsAdapter from "./ext/ApparatusLogsAdapter";
import AttributeAdapter from "./ext/AttributeAdapter";
import CalibrationPlacesAdapter from "./ext/CalibrationPlacesAdapter";
import CompanyAdapter from "./ext/CompanyAdapter";
import CostcenterAdapter from "./ext/CostcenterAdapter";
import CustomFieldContentAdapter from "./ext/CustomFieldContentAdapter";
import CustomFieldMetadataAdapter from "./ext/CustomFieldMetadataAdapter";
import ExtendedUserAdapter from "./ext/ExtendedUserAdapter";
import FAQAdapter from "./ext/FAQAdapter";
import FileExportAdapter from "./ext/FileExportAdapter";
import GlobalUserAdapter from "./ext/GlobalUserAdapter";
import InspectionAdapter from "./ext/InspectionAdapter";
import InspectionResultAdapter from "./ext/InspectionResultAdapter";
import InvoiceAdapter from "./ext/InvoiceAdapter";
import InvoiceRecipientAdapter from "./ext/InvoiceRecipientAdapter";
import MandantorAdapter from "./ext/MandantorAdapter";
import MandateAdapter from "./ext/MandateAdapter";
import OnboardingAdapter from "./ext/OnboardingAdapter";
import StatisticAdapter from "./ext/StatisticAdapter";
import SubscriptionAdapter from "./ext/SubscriptionAdapter";
import SubscriptiontypeAdapter from "./ext/SubscriptiontypeAdapter";
import TestplanAdapter from "./ext/TestplanAdapter";
import TicketAdapter from "./ext/TicketAdapter";
import UploadAdapter from "./ext/UploadAdapter";

const requestAdapter = new RequestAdapter();

requestAdapter.setBaseUrl(import.meta.env.VITE_BACKEND_URL);

export const authAdapter = new AuthAdapter(requestAdapter);

export const userAdapter = new UserAdapter(requestAdapter);

export const extendedUserAdapter = new ExtendedUserAdapter(requestAdapter);

export const globalUserAdapter = new GlobalUserAdapter(requestAdapter);

export const onboardingAdapter = new OnboardingAdapter(requestAdapter);

export const mandantorAdapter = new MandantorAdapter(requestAdapter);

export const mandateAdapter = new MandateAdapter(requestAdapter);

export const apparatusAdapter = new ApparatusAdapter(requestAdapter);

export const costcenterAdapter = new CostcenterAdapter(requestAdapter);

export const inspectionAdapter = new InspectionAdapter(requestAdapter);

export const inspectionResultAdapter = new InspectionResultAdapter(requestAdapter);

export const faqAdapter = new FAQAdapter(requestAdapter);

export const apparatusBasedataAdapter = new ApparatusBasedataAdapter(requestAdapter);

export const apparatusLocationAdapter = new ApparatusLocationAdapter(requestAdapter);

export const testplanAdapter = new TestplanAdapter(requestAdapter);

export const fileExportAdapter = new FileExportAdapter(requestAdapter);

export const apparatusCommentAdapter = new ApparatusCommentAdapter(requestAdapter);

export const ticketAdapter = new TicketAdapter(requestAdapter);

export const apparatusLogsAdapter = new ApparatusLogsAdapter(requestAdapter);

export const statisticAdapter = new StatisticAdapter(requestAdapter);

export const subscriptionAdapter = new SubscriptionAdapter(requestAdapter);

export const subscriptiontypeAdapter = new SubscriptiontypeAdapter(requestAdapter);

export const apiAdapter = new ApiAdapter(requestAdapter);

export const calibrationPlacesAdapter = new CalibrationPlacesAdapter(requestAdapter);

export const companyAdapter = new CompanyAdapter(requestAdapter);

export const uploadAdapter = new UploadAdapter(requestAdapter);

export const attributeAdapter = new AttributeAdapter(requestAdapter);

export const invoiceRecipientAdapter = new InvoiceRecipientAdapter(requestAdapter);

export const invoiceAdapter = new InvoiceAdapter(requestAdapter);

export const customFieldMetadataAdapter = new CustomFieldMetadataAdapter(requestAdapter);

export const customFieldContentAdapter = new CustomFieldContentAdapter(requestAdapter);
