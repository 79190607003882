/** @format */

import type { ComponentStyleConfig } from "@chakra-ui/theme";
import { defaultGap } from "@constants/styles";

import defaultColors from "../defaultColors";

// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
const Tabs: ComponentStyleConfig = {
    baseStyle: ({ colorMode, colorScheme }) => ({
        tab: {
            mr: 2,
            borderRadius: "5px",
            _hover: {
                color: `tabs.${colorScheme}.${colorMode}.hover.color`,
                bg: `tabs.${colorScheme}.${colorMode}.hover.bg`,
            },
            _focus: {
                outline: "none",
                boxShadow: "none",
            },
            _selected: {
                color: `tabs.${colorScheme}.${colorMode}.selected.color`,
                bg: `tabs.${colorScheme}.${colorMode}.selected.bg`,
                border: "none",
            },
            _disabled: {
                bg: defaultColors.grey[90],
                pointerEvents: "none",
            },
            _active: {
                outline: "none",
                boxShadow: "none",
            },
        },
        tablist: {
            px: 5,
            py: 3,
            border: "none",
            bg: `tabs.${colorScheme}.${colorMode}.bg`,
            borderRadius: "5px",
            boxShadow: "md",
        },
        root: {
            mb: defaultGap,
        },
    }),

    // The default size and variant values
    defaultProps: {
        colorScheme: "default",
    },
};

export const tabsColors = {
    default: {
        light: {
            color: defaultColors.grey[10],
            bg: defaultColors.white,
            selected: {
                color: defaultColors.grey[10],
                bg: defaultColors.blue[60],
            },
            hover: {
                color: defaultColors.blue[60],
                bg: defaultColors.grey[10],
            },
        },
        dark: {
            color: defaultColors.grey[20],
            bg: defaultColors.grey[100],
            selected: {
                color: defaultColors.grey[10],
                bg: defaultColors.blue[100],
            },
            hover: {
                color: defaultColors.blue[60],
                bg: defaultColors.grey[90],
            },
        },
    },
};

export default Tabs;
