/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

import { ExtendedResponseError } from "./ExtendedUserAdapter";

export interface RestorePasswordResponse {
    success?: boolean;
    errors?: ExtendedResponseError;
}

export default class OnboardingAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("core/Onboardings", "core/Onboarding", "core/Onboardings", adapter);
    }

    public async validateMail(token: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(
            "validate-mail",
            {
                token,
            },
            {
                params: {
                    t: token,
                },
            }
        );

        return data.success;
    }

    public async acceptInvite(token: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(
            "accept-invite",
            {
                token,
            },
            {
                params: {
                    t: token,
                },
            }
        );

        return data.success;
    }

    public async confirmUserEmailChange(token: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>(
            "confirm-user-email-change",
            {
                token,
            },
            {
                params: {
                    t: token,
                },
            }
        );

        return data.success;
    }

    public async resetPassword(username: string): Promise<boolean> {
        const { data } = await this._post<{ success: boolean }>("reset-password", {
            username,
        });

        return data.success;
    }

    public async restorePassword(
        newPassword: string,
        newPasswordAgain: string,
        token: string
    ): Promise<RestorePasswordResponse> {
        const { data } = await this._post<RestorePasswordResponse>(
            "restore-password",
            {
                newPassword,
                newPasswordAgain,
            },
            {
                params: {
                    t: token,
                },
            }
        );

        return data;
    }

    public async checkToken(token: string): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>("check-token", {
            params: {
                t: token,
            },
        });

        return data.success;
    }

    public async invite(users: number | number[]): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>(`invite-users`, {
            params: {
                users,
            },
        });

        return data.success;
    }

    public async sendValidateMail(): Promise<boolean> {
        const { data } = await this._get<{ success: boolean }>("send-validate-mail");

        return data.success;
    }
}
