/** @format */

import ControllerAdapter, { Model, Paginator } from "@api/auth/ControllerAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";
import { UploadImage } from "@typings/UploadTypes";

import { CompanyModel } from "./CompanyAdapter";

export interface MandantorModel extends Model {
    name: string;
    company?: string;
    company2?: string;
    address: string;
    zip: string;
    city: string;
    phone?: string;
    email?: string;
    www?: string;
    companies: CompanyModel[];
    uploads?: UploadImage[];
}

export type MandantorAddModel = Omit<MandantorModel, "companies" | "uploads">;

export interface CompanyListItem {
    [key: number]: string;
}

export default class MandantorAdapter<RequestConfigType> extends ControllerAdapter<
    RequestConfigType,
    Model,
    Paginator
> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super("core/mandantors", "core/mandantor", "core/mandantors", adapter);
    }

    public async getOwnMandantor(): Promise<MandantorModel> {
        const { data } = await this._get<{ mandantor: MandantorModel }>("editOwnMandantor");

        return data.mandantor;
    }

    public async setOwnMandantor(
        mandantor: MandantorAddModel,
        image: File | null
    ): Promise<boolean> {
        const formData = new FormData();
        if (image) {
            formData.append("clientUploads[file]", image);
        }

        formData.append("name", mandantor.name);
        if (mandantor.email) {
            formData.append("email", mandantor.email);
        }
        formData.append("address", mandantor.address);
        formData.append("zip", mandantor.zip);
        formData.append("city", mandantor.city);
        if (mandantor.www) {
            formData.append("www", mandantor.www);
        }
        if (mandantor.phone) {
            formData.append("phone", mandantor.phone);
        }
        if (mandantor.company) {
            formData.append("company", mandantor.company);
        }
        if (mandantor.company2) {
            formData.append("company2", mandantor.company2);
        }

        const { data } = await this._post<{ success: boolean }>("editOwnMandantor", formData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });

        return data.success;
    }
}
