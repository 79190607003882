/** @format */

import ApiAdapter from "@api/auth/ApiAdapter";
import { RequestAdapterInterface } from "@api/auth/RequestAdapterInterface";

export enum SubscriptionBundle {
    START = "start",
    MINI = "mini",
    BUSINESS = "business",
    PROFESSIONAL = "professional",
    INDIVIDUAL = "individual",
    TRIAL = "trial",
}

export enum SubscriptionInterval {
    MONTHLY = "1 month",
    QUARTERLY = "3 months",
    YEARLY = "12 months",
}

export type SubscriptionTypeBenefits = {
    headline?: string;
    benefits?: string[];
    special?: string[];
};

export type SubscriptionTypeOption = {
    id: number;
    description: string | null;
    benefits?: SubscriptionTypeBenefits | null;
    interval: SubscriptionInterval;
    count_max_apparatuses: number | null;
    base_price: number;
    allow_payment_on_account: boolean;
    base_price_gross: number;
    vat: number;
    subscriptiontype: SubscriptionType;
    cost?: SubscriptionTypeOptionCost;
    disabled?: boolean;
};

export type SubscriptionTypeOptionCost = {
    calculated_price: number;
    calculated_price_gross: number;
    price_diff: number;
    price_diff_gross: number;
    free_apparatuses: number | null;
    curr_apparatuses: number | null;
};

export type SubscriptionType = {
    id: number;
    title: SubscriptionBundle;
    color: string;
    apparatus_count: number | null;
    subscriptiontypeoptions?: SubscriptionTypeOption[];
    disabled?: boolean;
};

export default class SubscriptiontypeAdapter<
    RequestConfigType
> extends ApiAdapter<RequestConfigType> {
    constructor(adapter: RequestAdapterInterface<RequestConfigType>) {
        super(adapter, "payment/subscriptiontypes");
    }

    async getSubscriptionTypes(apparatus_count: number | null): Promise<SubscriptionType[]> {
        const response = apparatus_count
            ? await this._get<{ subscriptiontypes: SubscriptionType[] }>(`index/${apparatus_count}`)
            : await this._get<{ subscriptiontypes: SubscriptionType[] }>(`index`);

        const { subscriptiontypes } = response.data;

        if (!subscriptiontypes) {
            throw new Error("invalid response");
        }

        return subscriptiontypes;
    }

    async getSubscriptionBundle(
        title: SubscriptionBundle,
        interval: SubscriptionInterval
    ): Promise<SubscriptionTypeOption> {
        const response = await this._get<{ subscriptiontypeoption: SubscriptionTypeOption }>(
            `get-subscription-bundle/${title}/${interval}`
        );

        const { subscriptiontypeoption } = response.data;

        if (!subscriptiontypeoption) {
            throw new Error("invalid response");
        }

        return subscriptiontypeoption;
    }
}
